<template>
    <section class="tab-grupos">
        <div class="row mx-0 py-3 border-bottom">
            <div class="col my-auto pr-2">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
            </div>
            <div class="col-auto">
                <div class="bg-general d-middle-center rounded-circle wh-40px bs-036-29 cr-pointer" @click="$emit('new-grupo')">
                    <i class="icon-add text-white" />
                </div>
            </div>
        </div>
        <div v-for="(data,key) in chatsGrupos.filter(cg => !search || String(cg.nombre).toLowerCase().includes(search.toLowerCase()))"
             :key="key" 
             class="row mx-0 py-3 border-bottom cr-pointer"
             :class="{'bg-activo':grupoActivo.id_grupo ? grupoActivo.id_grupo == data.id_grupo : ''}"
        >
            <div class="col-9 pl-2" @click="getConversacion(data)">
                <div class="d-middle">
                    <div class="mr-2">
                        <img :src="data.imagen" alt="" class="obj-cover rounded-circle border" style="width: 45px; height: 45px;" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-15 f-500"> {{ data.nombre }}</p>
                        <p class="f-12 text-86 tres-puntos"> {{ data.cant_miembros }} Residentes </p>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right">
                <p class="f-10 text-86"> {{ setFecha(data.fecha_ultimo_chat) }} </p>
                <div class="d-flex justify-content-end mt-1">
                    <i v-if="!data.estado" class="icon-volume-off ml-auto my-auto" />
                    <div v-if="data.cant_no_leidos > 0" class="cant-sms f-10 text-white wh-20px rounded-circle d-middle-center ml-1 my-auto" style="background: #109881;">
                        {{ data.cant_no_leidos }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import { mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
export default {
    name: 'TabGrupos',
    data(){
        return{
           
            search: '',
        }
    },
    computed:{
        ...mapGetters({
            chatsGrupos:'chatsGrupos/chatsGrupos',
            grupoActivo:'chatsGrupos/grupoActivo',
            conjunto:'conjunto/conjunto'
        })
    },
    mounted(){
        this.getChatsGrupos()
        this.sockets.subscribe(`conjunto-${this.conjunto.id}-chat-grupal`, (data) => {
            this.$store.dispatch('chatsGrupos/updateListado',JSON.parse(data))
        });
    },
    methods:{
        ...mapMutations({
            setChatsGrupos: 'chatsGrupos/setChatsGrupos',
            setGrupoActivo: 'chatsGrupos/setGrupoActivo',
            setConversacionGrupo: 'chatsGrupos/setConversacionGrupo',
            clearConversacion: 'chatsGrupos/clearConversacion',
            setUsersGrupo: 'chatsGrupos/setUsersGrupo'

        }),
        async getChatsGrupos(){
            try {
                
                const {data} = await Chats.getChatsGrupos()
                this.setChatsGrupos(data.data.grupos)
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async getConversacion(payload){
            try {
                if(this.grupoActivo.id_grupo){
                    this.sockets.unsubscribe(`conjunto-${this.conjunto.id}-chat-grupo-${this.grupoActivo.id_grupo}`);
                }

                this.$store.dispatch('chatsGrupos/getDataGrupo', payload)
                this.$store.dispatch('chatsGrupos/getConversacion', payload)

                this.sockets.subscribe(`conjunto-${this.conjunto.id}-chat-grupo-${payload.id_grupo}`, (data) => {
                    console.log(data,'suscrito al chat');
                    this.$store.dispatch('chatsGrupos/setNewMensaje',JSON.parse(data))
                });

            } catch (error){
                this.errorCatch(error)
            }
        },
        setFecha(fecha){
            if(!fecha) return ''
            if(moment(fecha).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')){
                return moment(fecha).format('DD/MM/YYYY')
            }
            return moment(fecha).format('HH:mm a')
        }
    }
}
</script>
<style lang="scss" scoped>
.tab-grupos{
    .chat-seleccionado{
        background: #93edcf1a;
    }
    .con-sms{
        p{ color:#109881 !important; }
        .cant-sms{ background: #109881 ; }
    }
}
</style>